import { Container } from '@mui/system'
import React from 'react'
import Title from '../../components/title/Title'
import SecondNavbar from '../../layout/navbar/SecondNavbar'

const Refund = () => {
  return (
    <>
      <SecondNavbar />
      <section className="other">
        <Container maxWidth="xl">
          <Title title="ВОЗВРАТ И ОБМЕН ТОВАРОВ" />
          <p>9.1 Товары надлежащего качества:</p>
          <p>
            9.1.1. Покупатель вправе в течение десяти три со дня покупки
            обменять непродовольственный товар надлежащего качества на
            аналогичный у продавца, где он был приобретен, а в случае отсутствия
            такого товара в продаже — получить денежную компенсацию.
          </p>
          <p>
            9.1.2. Требования покупателя об обмене либо возврате товара подлежат
            удовлетворению в следующих случаях: - если товар не был в
            употреблении; - сохранены его товарный вид, потребительские
            свойства, пломбы, ярлыки; - документ, подтверждающий факт и условия
            покупки указанного товара (товарный или кассовый чек)
          </p>
          <p>
            9.1.3. Возврату не подлежат (не принимаются обратно) товары
            надлежащего качества, согласно Приложения 1 к Правилам розничной
            торговли Республики Узбекистан, утвержденное Постановлением Кабинета
            Министров Республики Узбекистан № 75 от 13 февраля 2003 года: Товары
            для профилактики и лечения заболеваний в домашних условиях (предметы
            санитарии и гигиены из металла, резины, текстиля и других
            материалов, изделия медицинского назначения и медицинская техника,
            средства гигиены полости рта, линзы очковые, предметы по уходу за
            детьми, лекарственные препараты). Предметы личной гигиены (зубные
            щетки, расчески, заколки, бигуди для волос, парики, шиньоны и другие
            аналогичные товары). Парфюмерно-косметические товары. Текстильные
            товары (хлопчатобумажные, льняные, шелковые, шерстяные и
            синтетические ткани, товары из нетканых материалов типа тканей —
            ленты, тесьма, кружево и другие); кабельная продукция (провода,
            шнуры, кабели); строительные и отделочные материалы (линолеум,
            пленка, ковровые покрытия и другие) и другие товары, отпускаемые на
            метраж. Швейные и трикотажные изделия (изделия швейные и трикотажные
            бельевые, чулочно-носочные и перчаточные изделия). Изделия и
            материалы, контактирующие с пищевыми продуктами, из полимерных
            материалов, в том числе для разового использования (посуда и
            принадлежности столовые и кухонные, емкости и упаковочные материалы
            для хранения и транспортировки пищевых продуктов). Товары бытовой
            химии, пестициды и агрохимикаты. Мебель (мебельные гарнитуры и
            комплекты). Изделия из драгоценных металлов, с драгоценными камнями,
            из драгоценных металлов со вставками из полудрагоценных и
            синтетических камней, ограненные драгоценные камни. Автомобили и
            мото-вело товары, прицепы и номерные агрегаты к ним; мобильные
            средства малой механизации сельскохозяйственных работ; прогулочные
            суда и иные плавсредства бытового назначения. Технически сложные
            товары бытового назначения, на которые установлены гарантийные сроки
            (станки металлорежущие и деревообрабатывающие бытовые;
            электробытовые машины и бытовые электроприборы; бытовая
            радиоэлектронная аппаратура; бытовая вычислительная и множительная
            техника; фото- и киноаппаратура; телефонные аппараты и факсимильная
            аппаратура; электромузыкальные инструменты; игрушки электронные).
            Животные и растения.
          </p>
          <p>
            9.1.4. Возврат товара осуществляется посредством «Служба доставки»
            Продавца, для этого необходимо: - позвонить в интернет-магазин по
            телефонам, указанным в разделе «Контакты»; - распечатать и заполнить
            «Заявление на возврат»; - приложить к заявлению на возврат товарный
            или кассовый чек заказа.
          </p>
          <p>9.2 Товары ненадлежащего качества:</p>
          <p>
            9.2.1. Потребитель, которому продан товар с недостатками если
            недостатки обнаружены в течение гарантийного срока либо срока
            годности товара или в течении шести месяцев по товарам на которые не
            установлены сроки имеет требовать: - безвозмездного устранения
            недостатков товара или возмещения расходов на исправление
            недостатков потребителем либо третьим лицом; - соразмерного
            уменьшения покупной цены; Требования потребителя рассматриваются при
            предъявлении им кассового или товарного чека, а по товарам, на
            которые установлены гарантийные сроки, — надлежаще оформленного
            технического паспорта или иного заменяющего его документа.
          </p>
          <p>
            9.2.2. Процедура возврата товара ненадлежащего качества
            осуществляется следующим образом: - Путем предоставления заявлении
            на возврат Товара, в котором должно быть указано, какой именно
            дефект содержит возвращаемый Покупателем товар; - После принятия
            заявления, Товар направляется на проверку в техническую службу по
            оказанию сервисных услуг, который уполномочен производить
            техническую проверку определенного вида Товара для определения
            возникновения причин недостатков Товара; - По итогам проверки
            уполномоченной технической службой по оказанию сервисных услуг
            составляется заключение о причинах недостатков Товара. - В случае
            если в период гарантийного срока недостатки Товара явились причиной
            заводского брака (дефекта), Товар подлежит замене, а в случае
            возможности технического устранения недостатков, Товар подлежит
            безвозмездному устранения недостатков.
          </p>
          <p>
            9.2.3. Порядок замены товара с недостатками: - В течении 7 дней (при
            явных признаках заводского брака) - В течении 20 дней (при
            необходимости дополнительной проверки) - В течении 1 месяца (при
            отсутствии товара аналогичной марки) - В течении 2 месяцев (при
            доставке в отдаленные территории).
          </p>
          <p>
            9.2.4. Порядок устранения недостатков товара: - В течении 20 дней
            (после полной проверки технической службой по оказанию сервисных
            услуг).
          </p>
          <p>
            9.2.5. После истечения гарантийного срока, претензии предъявляются
            производителю в течении срока службы.
          </p>
        </Container>
      </section>
    </>
  );
}

export default Refund