export const countrySource = [
  {
    id: 1,
    label: "г.Ташкент",
    value: "г.Ташкент"
  },
  {
    id: 2,
    label: "Ташкентская область",
    value: "Ташкентская область"
  },
  {
    id: 3,
    label: "Андижанская область",
    value: "Андижанская область"
  },
  {
    id: 4,
    label: "Бухарская область",
    value: "Бухарская область"
  },
  {
    id: 5,
    label: "Джизакская область",
    value: "Джизакская область"
  },
  {
    id: 6,
    label: "Кашкадарьинская область",
    value: "Кашкадарьинская область"
  },
  {
    id: 7,
    label: "Навоийская область",
    value: "Навоийская область"
  },
  {
    id: 8,
    label: "Наманганская область",
    value: "Наманганская область "
  },
  {
    id: 9,
    label: "Самаркандская область",
    value: "Самаркандская область"
  },
  {
    id: 10,
    label: "Сурхандарьинская область",
    value: "Сурхандарьинская область"
  },
  {
    id: 11,
    label: "Сырдарьинская область",
    value: "Сырдарьинская область"
  },
  {
    id: 12,
    label: "Ферганская область",
    value: "Ферганская область"
  },
  {
    id: 13,
    label: "Хорезмская область",
    value: "Хорезмская область"
  },
  {
    id:14,
    label: "Каракалпакстан",
    value: "Каракалпакстан",
  }
]

export const towns = {
  1:[
    {
      value: "Алмазарский район",
      label: "Алмазарский район"
    },
    {
      value: "Бектемирский район",
      label: "Бектемирский район"
    },
    {
      label: "Мирабадский район",
      value: "Мирабадский район"
    },
    {
      label:"Мирзо-Улугбекский район",
      value:"Мирзо-Улугбекский район"
    },
    {
      label:"Сергелийский район",
      value:"Сергелийский район"
    },
    {
      label:"Учтепинский район",
      value:"Учтепинский район"
    },
    {
      label:"Чиланзарский район",
      value:"Чиланзарский район"
    },
    {
      label:"Шайхантахурский район",
      value:"Шайхантахурский район"
    },
    {
      label:"Юнусабадский район",
      value:"Юнусабадский район"
    },
    {
      label:"Яккасарайский район",
      value:"Яккасарайский район"
    },
    {
      label:"Яшнабадский район",
      value:"Яшнабадский район"
    },
    
  ],
  2: [
    {
      id: 1,
      label: "Аккурган",
      value: "Аккурган",
    },
    {
      id: 2,
      label: "Алмалык",
      value: "Алмалык",
    },
    {
      id: 3,
      label: "Ангрен",
      value: "Ангрен",
    },
    {
      id: 4,
      label: "Ахангаран",
      value: "Ахангаран",
    },
    {
      id: 5,
      label: "Бекабад",
      value: "Бекабад",
    },
    {
      id: 6,
      label: "Большой Чимган",
      value: "Большой Чимган",
    },
    // 
    {
      id: 7,
      label: "Бука",
      value: "Бука",
    },
    {
      id: 6,
      label: "Газалкент",
      value: "Газалкент",
    },
    {
      id: 6,
      label: "Гульбахор",
      value: "Гульбахор",
    },
    {
      id: 6,
      label: "Дурмень",
      value: "Дурмень",
    },
    {
      id: 6,
      label: "Зангиата",
      value: "Зангиата",
    },
    {
      id: 6,
      label: "Зафар",
      value: "Зафар",
    },
    {
      label: "Искандар",
      value: "Искандар"
    },
    {
      label: "Келес",
      value: "Келес"
    },
    {
      label: "Кибрай",
      value: "Кибрай"
    },
    {
      label: "Коксарай",
      value: "Коксарай"
    },
    {
      label: "Красногорск",
      value: "Красногорск"
    },
    {
      label: "Назарбек",
      value: "Назарбек"
    },
    {
      label: "Нурафшан (Тойтепа)",
      value: "Нурафшан (Тойтепа)"
    },
    {
      label: "ПаркентПскент",
      value: "ПаркентПскент"
    },
    {
      label: "Ходжикент",
      value: "Ходжикент"
    },
    {
      label: "Чарвак",
      value: "Чарвак"
    },
    {
      label: "Чиназ",
      value: "Чиназ"
    },
    {
      label: "Чирчик",
      value: "Чирчик"
    },
    {
      label: "Эшангузар",
      value: "Эшангузар"
    },
    {
      label: "Янгибазар",
      value: "Янгибазар"
    },
    {
      label: "Янгиюль",
      value: "Янгиюль"
    },
  ],
  3: [
    {
      label: "Акалтын",
      value: "Акалтын"
    },
    {
      label: "Алтынкуль",
      value: "Алтынкуль"
    },
    {
      label: "Андижан",
      value: "Андижан"
    },
    {
      label: "Асака",
      value: "Андижан"
    },
    {
      label: "Ахунбабаев",
      value: "Ахунбабаев"
    },
    {
      label: "Балыкчи",
      value: "Балыкчи"
    },
    {
      label: "Боз",
      value: "Боз"
    },
    {
      label: "Булакбаши",
      value: "Булакбаши"
    },
    {
      label: "Карасу",
      value: "Карасу"
    },
    {
      label: "Куйганъяр",
      value: "Куйганъяр"
    },
    {
      label: "Кургантепа",
      value: "Кургантепа"
    },
    {
      label: "Мархамат",
      value: "Мархамат"
    },
    {
      label: "Пайтуг",
      value: "Пайтуг"
    },
    {
      label: "Пахтаабад",
      value: "Пахтаабад"
    },
    {
      label: "Ханабад",
      value: "Ханабад"
    },
    {
      label: "Ходжаабад",
      value: "Ходжаабад"
    },
    {
      label: "Шахрихан",
      value: "Шахрихан"
    },
  ],
  4: [
    {
      label:"Алат",
      value:"Алат"
    },
    {
      label:"Бухара",
      value:"Бухара"
    },
    {
      label:"Вабкент",
      value:"Вабкент"
    },
    {
      label:"Газли",
      value:"Газли"
    },
    {
      label:"Галаасия",
      value:"Галаасия"
    },
    {
      label:"Гиждуван",
      label:"Гиждуван"
    },
    {
      label:"Жондор",
      value:"Жондор"
    },
    {
      label:"Каган",
      value:"Каган"
    },
    {
      label:"Каракуль",
      value:"Каракуль"
    },
    {
      label:"Караулбазар",
      value:"Караулбазар"
    },
    {
      label:"Ромитан",
      value:"Ромитан"
    },
    {
      label:"Шафиркан",
      value:"Шафиркан"
    },
  ],
  5: [
    {
      label:"Айдаркуль",
      value:"Айдаркуль"
    },
    {
      label:"Баландчакир",
      value:"Баландчакир"
    },
    {
      label:"Гагарин",
      value:"Гагарин"
    },
    {
      label:"Галлаарал",
      value:"Галлаарал"
    },
    {
      label:"Голиблар",
      value:"Голиблар"
    },
    {
      label:"Даштобод",
      value:"Даштобод"
    },
    {
      label:"Джизак",
      value:"Джизак"
    },
    {
      label:"Дустлик",
      value:"Дустлик"
    },
    {
      label:"Заамин",
      value:"Заамин"
    },
    {
      label:"Зарбдар",
      value:"Зарбдар"
    },
    {
      label:"Зафарабад",
      value:"Зафарабад"
    },
    {
      label:"Марджанбулак",
      value:"Марджанбулак"
    },
    {
      label:"Пахтакор",
      value:"Пахтакор"
    },
    {
      label:"Усмат",
      value:"Усмат"
    },
    {
      label:"Учтепа",
      value:"Учтепа"
    },
    {
      label:"Янгикишлак",
      value:"Янгикишлак"
    },
  ],
  6: [
    {
      label:"Бешкент",
      value:"Бешкент"
    },
    {
      label:"Гузар",
      value:"Гузар"
    },
    {
      label:"Дехканабад",
      value:"Дехканабад"
    },
    {
      label:"Камаши",
      value:"Камаши"
    },
    {
      label:"Карашина",
      value:"Карашина"
    },
    {
      label:"Карши",
      value:"Карши"
    },
    {
      label:"Касан",
      value:"Касан"
    },
    {
      label:"Касби",
      value:"Касби"
    },
    {
      label:"Китаб",
      value:"Китаб"
    },
    {
      label:"Мубарек",
      value:"Мубарек"
    },
    {
      label:"Муглан",
      value:"Муглан"
    },
    {
      label:"Талимарджан",
      value:"Талимарджан"
    },
    {
      label:"Чиракчи",
      value:"Чиракчи"
    },
    {
      label:"Шахрисабз",
      value:"Шахрисабз"
    },
    {
      label:"Яккабаг",
      value:"Яккабаг"
    },
    {
      label:"Янги-Миришкор",
      value:"Янги-Миришкор"
    },
    {
      label:"Янги-Нишан",
      value:"Янги-Нишан"
    },
  ],
  7: [
    {
      label:"Бешрабат",
      value:"Бешрабат"
    },
    {
      label:"Зарафшан",
      value:"Зарафшан"
    },
    {
      label:"Канимех",
      value:"Канимех"
    },
    {
      label:"Кармана",
      value:"Кармана"
    },
    {
      label:"Кызылтепа",
      value:"Кызылтепа"
    },
    {
      label:"Навои",
      value:"Навои"
    },
    {
      label:"Нурата",
      value:"Навои"
    },
    {
      label:"Тамдыбулак",
      value:"Навои"
    },
    {
      label:"Учкудук",
      value:"Навои"
    },
    {
      label:"Янгирабат",
      value:"Навои"
    },
  ],
  8: [
    {
      label:"Джумашуй",
      value:"Джумашуй"
    },
    {
      label:"Касансай",
      value:"Касансай"
    },
    {
      label:"Наманган",
      value:"Наманган"
    },
    {
      label:"Пап",
      value:"Пап"
    },
    {
      label:"Ташбулак",
      value:"Ташбулак"
    },
    {
      label:"Туракурган",
      value:"Туракурган"
    },
    {
      label:"Учкурган",
      value:"Учкурган"
    },
    {
      label:"Хаккулабад",
      value:"Хаккулабад"
    },
    {
      label:"Чартак",
      value:"Чартак"
    },
    {
      label:"Челак",
      value:"Челак"
    },
    {
      label:"Чуст",
      value:"Чуст"
    },
  ],
  9: [
    {
      label:"Акташ",
      value:"Акташ"
    },
    {
      label:"Булунгур",
      value:"Булунгур"
    },
    {
      label:"Гузалкент",
      value:"Гузалкент"
    },
    {
      label:"Гюлабад",
      value:"Гюлабад"
    },
    {
      label:"Дарбанд",
      value:"Дарбанд"
    },
    {
      label:"Джамбай",
      value:"Джамбай"
    },
    {
      label:"Джума",
      value:"Джума"
    },
    {
      label:"Зиадин",
      value:"Зиадин"
    },
    {
      label:"Иштыхан",
      value:"Иштыхан"
    },
    {
      label:"Каттакурган",
      value:"Каттакурган"
    },
    {
      label:"Кушрабад",
      value:"Кушрабад"
    },
    {
      label:"Лаиш",
      value:"Лаиш"
    },
    {
      label:"Нурабад",
      value:"Нурабад"
    },
    {
      label:"Пайарык",
      value:"Пайарык"
    },
    {
      label:"Пайшанба",
      value:"Пайшанба"
    },
    {
      label:"Самарканд",
      value:"Самарканд"
    },
    {
      label:"Тайлак",
      value:"Тайлак"
    },
    {
      label:"Ургут",
      value:"Ургут"
    },
    {
      label:"Чилек",
      value:"Чилек"
    },
  ],
  10: [
    {
      label:"Ангор",
      value:"Ангор"
    },
    {
      label:"Байсун",
      value:"Байсун"
    },
    {
      label:"Бандихон",
      value:"Бандихон"
    },
    {
      label:"Денау",
      value:"Бандихон"
    },
    {
      label:"Джаркурган",
      value:"Джаркурган"
    },
    {
      label:"Карлук",
      value:"Карлук"
    },
    {
      label:"Кизирик",
      value:"Кизирик"
    },
    {
      label:"Кумкурган",
      value:"Кумкурган"
    },
    {
      label:"Музрабад",
      value:"Музрабад"
    },
    {
      label:"Сариасия",
      value:"Сариасия"
    },
    {
      label:"Сарык",
      value:"Сарык"
    },
    {
      label:"Термез",
      value:"Термез"
    },
    {
      label:"Узун",
      value:"Узун"
    },
    {
      label:"Учкызыл",
      value:"Учкызыл"
    },
    {
      label:"Халкабад",
      value:"Халкабад"
    },
    {
      label:"Шаргунь",
      value:"Шаргунь"
    },
    {
      label:"Шерабад",
      value:"Шерабад"
    },
    {
      label:"Шурчи",
      value:"Шурчи"
    },
    
  ],
  11: [
    {
      label:"Cырдарья",
      value:"Cырдарья"
    },
    {
      label:"Бахт",
      value:"Бахт"
    },
    {
      label:"Баяут",
      value:"Баяут"
    },
    {
      label:"Гулистан",
      value:"Гулистан"
    },
    {
      label:"Навруз",
      value:"Навруз"
    },
    {
      label:"Сайхун",
      value:"Сайхун"
    },
    {
      label:"Сардоба",
      value:"Сардоба"
    },
    {
      label:"Сырдарья",
      value:"Сырдарья"
    },
    {
      label:"Теренозек",
      value:"Теренозек"
    },
    {
      label:"Хаваст",
      value:"Хаваст"
    },
    {
      label:"Ширин",
      value:"Ширин"
    },
    {
      label:"Янгиёр",
      value:"Янгиёр"
    },
    {
      label:"Янгиер",
      value:"Янгиер"
    },
    
  ],
  12: [
    {
      label:"Алтыарык",
      value:"Алтыарык"
    },
    {
      label:"Багдад",
      value:"Багдад"
    },
    {
      label:"Бешарык",
      value:"Бешарык"
    },
    {
      label:"Вуадиль",
      value:"Вуадиль"
    },
    {
      label:"Дангара",
      value:"Дангара"
    },
    {
      label:"Коканд",
      value:"Коканд"
    },
    {
      label:"Кува",
      value:"Кува"
    },
    {
      label:"Кувасай",
      value:"Кувасай"
    },
    {
      label:"Лангар",
      value:"Лангар"
    },
    {
      label:"Маргилан",
      value:"Маргилан"
    },
    {
      label:"Навбахор",
      value:"Навбахор"
    },
    {
      label:"Раван",
      value:"Раван"
    },
    {
      label:"Риштан",
      value:"Риштан"
    },
    {
      label:"Ташлак",
      value:"Ташлак"
    },
    {
      label:"Учкуприк",
      value:"Учкуприк"
    },
    {
      label:"Фергана",
      value:"Фергана"
    },
    {
      label:"Хамза",
      value:"Хамза"
    },
    {
      label:"Шахимардан",
      value:"Шахимардан"
    },
    {
      label:"Язъяван",
      value:"Язъяван"
    },
    {
      label:"Яйпан",
      label:"Яйпан"
    },
    {
      label:"Янги Маргилан",
      value:"Янги Маргилан"
    },
    {
      label:"Янгикурган",
      value:"Янгикурган"
    },
    
  ],
  13: [
    {
      label:"Багат",
      value:"Багат"
    },
    {
      label:"Гурлен",
      value:"Гурлен"
    },
    {
      label:"Караул",
      value:"Караул"
    },
    {
      label:"Кошкупыр",
      value:"Кошкупыр"
    },
    {
      label:"Питнак",
      value:"Питнак"
    },
    {
      label:"Ургенч",
      value:"Ургенч"
    },
    {
      label:"Хазарасп",
      value:"Хазарасп"
    },
    {
      label:"Ханка",
      value:"Ханка"
    },
    {
      label:"Хива",
      value:"Хива"
    },
    {
      label:"Чалыш",
      value:"Чалыш"
    },
    {
      label:"Шават",
      value:"Шават"
    },
    {
      label:"Янгиарык",
      value:"Янгиарык"
    },

  ],
  14: [
    {
      label:"Акмангит",
      value:"Акмангит"
    },
    {
      label:"Беруни",
      value:"Беруни"
    },
    {
      label:"Бустан",
      value:"Бустан"
    },
    {
      label:"Канлыкуль",
      value:"Канлыкуль"
    },
    {
      label:"Караузяк",
      value:"Караузяк"
    },
    {
      label:"Кегейли",
      value:"Кегейли"
    },
    {
      label:"Кунград",
      value:"Кунград"
    },
    {
      label:"Мангит",
      value:"Мангит"
    },
    {
      label:"Муйнак",
      value:"Муйнак"
    },
    {
      label:"Нукус",
      value:"Нукус"
    },
    {
      label:"Тахиаташ",
      value:"Тахиаташ"
    },
    {
      label:"Тахтакупыр",
      value:"Тахтакупыр"
    },
    {
      label:"Турткуль",
      value:"Турткуль"
    },
    {
      label:"Ходжейли",
      value:"Ходжейли"
    },
    {
      label:"Чимбай",
      value:"Чимбай"
    },
    {
      label:"Шуманай",
      value:"Шуманай"
    },
  ],
}
  
  