import { Container } from '@mui/system';
import React from 'react'
import { Link } from 'react-router-dom';
import Title from '../../components/title/Title'
import SecondNavbar from '../../layout/navbar/SecondNavbar'

const About = () => {
  return (
    <>
      <SecondNavbar />
      <section className="other">
        <Container maxWidth="xl">
          <div className="pages">
            <Link to="/" className='gray'>Магазин /</Link>
            <div>О компании</div>
          </div>
          <Title title="О компании" />
          <p>
            Разместите на этой странице информацию с описанием вашего магазина.
          </p>
          <p>Например:</p>
          <p>
            Интернет-магазин www.Gipermart.uz – лучшие товары по низким ценам!
          </p>
          <p>
            Мы рады предложить вам недорогие, но качественные товары с
            подробными описаниями, характеристиками и фотографиями. У нас Вы
            можете купить замечательные товары: технику, электронику, одежду,
            обувь, игрушки, книги и многое другое в вашем регионе по ценам
            производителей и без наценки.
          </p>
          <p>
            Продажа большого ассортимента разнообразных товаров – основная
            специализация нашего интернет-магазина. Мы доставим ваш заказ
            бесплатно в любой уголок мира, осуществим подробную консультацию по
            товарам и поможем с выбором.
          </p>
          <p>
            Магазин gipermart.uz предлагает Вам купить качественную и доступную
            технику, электронику, одежду, обувь, игрушки, книги и многое другое
            с доставкой! Все виды современных товаров от эконом класса до более
            дорогих представлены в нашем каталоге.
          </p>
          <p>
            Вы можете купить любые товары в вашем городе: технику, электронику,
            одежду, обувь, игрушки, книги и многое другое.
          </p>
          <p className="f-bold">Наши главные преимущества:</p>
          <p className="xl:pl-16 lg:pl-12 md:pl-8 pl-4 mt-6">
            Низкие цены от производителей
          </p>
          <p className="xl:pl-16 lg:pl-12 md:pl-8 pl-4">
            Доставка по городу в день заказа
          </p>
          <p className="xl:pl-16 lg:pl-12 md:pl-8 pl-4">
            Доставка заказов Почтой по всей Стране за 5-15 дней
          </p>
          <p className="xl:pl-16 lg:pl-12 md:pl-8 pl-4">
            Только оригинальная и сертифицированная продукция
          </p>
          <p className="xl:pl-16 lg:pl-12 md:pl-8 pl-4">
            Гарантия на все товары – 5 лет!
          </p>
          <p className="xl:pl-16 lg:pl-12 md:pl-8 pl-4">
            Не понравился товар? Вернем или обменяем в течение 14-ти дней без
            оформления лишних бумаг!
          </p>
          <p className="xl:pl-16 lg:pl-12 md:pl-8 pl-4 !mb-6">
            Бонусы и скидки для постоянных покупателей
          </p>
          <p>
            Эту страницу можно отредактировать в бэк-офисе сайта в разделе Меню
            и страницы
          </p>
        </Container>
      </section>
    </>
  );
}

export default About