import { Container } from '@mui/system'
import React from 'react'
import Title from '../../components/title/Title'
import SecondNavbar from '../../layout/navbar/SecondNavbar'

const Delivery = () => {
  return (
    <>
      <SecondNavbar />
      <section className="other">
        <Container maxWidth="xl">
          <Title title="ДОСТАВКА И ПЕРЕДАЧА ТОВАРА" />
          <p>
            8.1. Продавец оказывает Покупателю услуги по доставке Товара одним,
            из способов, указанных на сайте Интернет-магазина в разделе «Тип
            доставки»
          </p>
          <p>
            8.2. Если договор купли-продажи Товара дистанционным способом
            заключен с условием о доставке Товара Покупателю, Продавец обязан в
            установленный Договором срок доставить Товар в место, указанное
            Покупателем, а если место доставки Товара Покупателем не указано, то
            по месту его жительства или регистрации, о чем Продавец ставит в
            известность Покупателя.
          </p>
          <p>
            8.3. Место доставки Товара Покупатель указывает при оформлении
            Заказа на приобретение Товара в разделе «Способ доставки»
          </p>
          <p>
            8.4. Срок доставки Товара Покупателю состоит из срока обработки
            заказа и срока доставки.
          </p>
          <p>
            8.5. Продавец указывает стоимость доставки Товара на сайте
            Интернет-магазина либо сообщает Покупателю при оформлении заказа
            Оператором.
          </p>
          <p>
            8.6. Продавец передает, а Покупатель принимает и оплачивает товары
            на условиях, указанных в настоящем договоре. Право собственности на
            Заказанные Товары переходит к Покупателю с момента фактической
            передачи Товара Покупателю и оплаты последним полной стоимости
            Товара. Риск случайной гибели или повреждения Товара переходит к
            Покупателю с момента фактической передачи Товара Покупателю.
          </p>
        </Container>
      </section>
    </>
  );
}

export default Delivery