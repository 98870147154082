import { Container } from "@mui/system";
import React from "react";
import Title from "../../components/title/Title";
import SecondNavbar from "../../layout/navbar/SecondNavbar";

const Information = () => {
  return (
    <>
      <SecondNavbar />
      <section className="other">
        <Container maxWidth="xl">
          <Title title="ФОРС-МАЖОР" />
          <p>
            11.1. Любая из Сторон освобождается от ответственности за полное или
            частичное неисполнение своих обязательств по настоящему Договору,
            если это неисполнение было вызвано обстоятельствами непреодолимой
            силы, возникшими после подписания настоящего Договора.
          </p>
          <p>
            11.2. «Обстоятельства Непреодолимой Силы» означают чрезвычайные
            события или обстоятельства, которые такая Сторона не могла
            предвидеть или предотвратить доступными ей средствами. Такие
            чрезвычайные события или обстоятельства включают в себя, в
            частности: забастовки, наводнения, пожары, землетрясения и иные
            стихийные бедствия, войны, военные действия, действия
            государственных органов Республики Узбекистан или иных государств, а
            также любые иные обстоятельства, выходящие за пределы разумного
            контроля любой из Сторон.
          </p>
          <p>
            11.3. Изменения действующего законодательства или нормативных актов,
            прямо или косвенно влияющие на какую-либо из Сторон, не
            рассматриваются как Обстоятельства непреодолимой силы, однако, в
            случае внесения таких изменений, которые не позволяют любой из
            Сторон исполнить какие-либо из ее обязательств по настоящему
            Договору, Стороны обязаны незамедлительно принять решение
            относительно порядка работы по устранению этой проблемы с тем, чтобы
            обеспечить Сторонам продолжение исполнения настоящего Договора.
          </p>
          <Title title="ОТВЕТСТВЕННОСТЬ СТОРОН" />
          <p>
            12.1. За неисполнение или ненадлежащее исполнение условий настоящего
            Договора Стороны несут ответственность в соответствии с
            законодательством Республики Узбекистан.
          </p>
          <p>
            12.2. Вся текстовая информация, графические изображения и услуги,
            размещенные в интернет-магазине, имеют законного правообладателя,
            незаконное использование указанной информации и изображений
            преследуется в соответствии с действующим законодательством.
          </p>
          <Title title="ПРОЧИЕ УСЛОВИЯ" />
          <p>
            13.1. Все споры, связанные с неисполнением, или ненадлежащим
            исполнением своих обязательств по настоящему договору стороны будут
            стараться решить в ходе переговоров.
          </p>
          <p>
            13.2. В случае не достижения согласия в ходе переговоров, споры
            будут разрешаться в судебном порядке в соответствии с действующим
            законодательством Республики Узбекистан.
          </p>
          <p>
            13.3. Разделы интернет-магазина, разъясняющие правила и условия, на
            которые имеются ссылки в Оферте являются неотъемлемой частью
            настоящей Оферты.
          </p>
          <p>
            13.4. Акцептируя настоящую оферту, Покупатель выражает согласие и
            разрешает ООО «FRIENDS CAPITAL» обрабатывать свои персональные
            данные, в том числе фамилию, имя, отчество, дату рождения, пол,
            место работы и должность, почтовый адрес, домашний, рабочий,
            мобильный телефоны, адрес электронной почты, включая сбор,
            систематизацию, накопление, хранение, уточнение (обновление,
            изменение), использование, распространение, передачу на территории
            Республики Узбекистан, обезличивание, блокирование, уничтожение
            персональных данных, а также передачу их контрагентам Продавца с
            целью дальнейшей обработки (включая сбор, систематизацию,
            накопление, хранение, уточнение (обновление, изменение),
            использование, распространение на территории Республики Узбекистан,
            обезличивание, блокирование, уничтожение персональных данных) для
            проведения исследований, направленных на улучшение качества услуг,
            для проведения маркетинговых программ, статистических исследований,
            а также для продвижения услуг на рынке путем осуществления прямых
            контактов с Покупателем с помощью различных средств связи, включая,
            но, не ограничиваясь: почтовая рассылка, электронная почта, телефон,
            факсимильная связь, сеть Интернет.
          </p>
          <p>
            13.5. Покупатель выражает согласие и разрешает Продавцу и
            контрагентам Продавца обрабатывать персональные данные Покупателя, с
            помощью автоматизированных систем управления базами данных, а также
            иных программных средств, специально разработанных по поручению
            Продавца. Работа с такими системами осуществляется по предписанному
            оператором алгоритму (сбор, систематизация, накопление, хранение,
            уточнение, использование, блокирование, уничтожение). Используемые
            способы обработки (включая, но, не ограничиваясь): автоматическая
            сверка почтовых кодов с базой кодов, автоматическая проверка
            написания названий улиц\населенных пунктов, уточнение данных с
            Покупателем путем телефонной, почтовой связи с Покупателем или с
            помощью контакта через сеть Интернет, сегментация базы по заданным
            критериям. Покупатель соглашается с тем, что, если это необходимо
            для реализации целей, указанных в настоящей оферте, его персональные
            данные, полученные Продавцом, могут быть переданы третьим лицам,
            которым Продавец может поручить обработку персональных данных
            Покупателя на основании договора, заключенного с такими лицами, при
            условии соблюдения требований законодательства Республики Узбекистан
            об обеспечении такими третьими лицами конфиденциальности
            персональных данных и безопасности персональных данных при их
            обработке. При передаче указанных данных Покупателя Продавец
            предупреждает лиц, получающих персональные данные Покупателя, о том,
            что эти данные являются конфиденциальными и могут быть использованы
            лишь в целях, для которых они сообщены, и требуют от этих лиц
            соблюдения этого правила.
          </p>
          <p>
            13.6. Покупатель вправе запросить у Продавца полную информацию о
            своих персональных данных, их обработке и использовании, а также
            потребовать исключения или исправления/дополнения неверных или
            неполных персональных данных, отправив соответствующий письменный
            запрос на имя Продавца на почтовый адрес. Данное Покупателем
            согласие на обработку его персональных данных является бессрочным и
            может быть отозвано посредством направления Покупателем письменного
            заявления в адрес Продавца на почтовый адрес.
          </p>
        </Container>
      </section>
    </>
  );
};

export default Information;
