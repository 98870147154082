import React from 'react';

const EyeDisabled = (props) => (
  <svg
    width="22"
    height="20"
    viewBox="0 0 22 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M20.83 9.44197C19.756 7.92188 18.4726 6.56111 17.018 5.39997L20.707 1.71097L19.293 0.292969L15.327 4.25897C14.0189 3.46974 12.5273 3.03576 11 2.99997C5.559 2.99997 1.347 9.17997 1.17 9.44197L0.799999 9.99997L1.175 10.558C2.24749 12.0777 3.52913 13.4385 4.982 14.6L1.293 18.293L2.707 19.707L6.673 15.741C7.98112 16.5302 9.47265 16.9642 11 17C16.441 17 20.653 10.82 20.83 10.558L21.205 9.99997L20.83 9.44197ZM3.242 9.99997C4.336 8.58997 7.469 4.99997 11 4.99997C11.9947 5.02154 12.9711 5.27238 13.853 5.73297L13.019 6.56697C12.4094 6.19937 11.7118 6.00345 11 5.99997C9.93913 5.99997 8.92172 6.4214 8.17157 7.17154C7.42143 7.92169 7 8.9391 7 9.99997C7.00348 10.7118 7.1994 11.4094 7.567 12.019L6.407 13.179C5.22329 12.2557 4.16 11.1877 3.242 9.99997V9.99997ZM13 9.99997C13 10.5304 12.7893 11.0391 12.4142 11.4142C12.0391 11.7893 11.5304 12 11 12C10.8271 11.9976 10.6553 11.9724 10.489 11.925L12.925 9.48897C12.9724 9.65525 12.9976 9.82707 13 9.99997ZM9 9.99997C9 9.46954 9.21071 8.96083 9.58579 8.58576C9.96086 8.21068 10.4696 7.99997 11 7.99997C11.1729 8.00232 11.3447 8.02754 11.511 8.07497L9.075 10.511C9.02757 10.3447 9.00235 10.1729 9 9.99997ZM11 15C10.0053 14.9784 9.02894 14.7276 8.147 14.267L8.981 13.433C9.59056 13.8006 10.2882 13.9965 11 14C12.0609 14 13.0783 13.5785 13.8284 12.8284C14.5786 12.0783 15 11.0608 15 9.99997C14.9965 9.28815 14.8006 8.59053 14.433 7.98097L15.593 6.82097C16.7767 7.7442 17.84 8.81219 18.758 9.99997C17.664 11.41 14.531 15 11 15Z"
      fill="currentColor"
    />
  </svg>
);

export default EyeDisabled;
