export const FETCH_PRODUCTS = "PRODUCT/FETCH_PRODUCTS";
export const SHOW_LOADER = "PRODUCT/SHOW_LOADER";
export const HIDE_LOADER = "PRODUCT/HIDE_LOADER";
export const FETCH_CATEGORIES = "PRODUCT/CATEGORIES";
export const CREATE_USER = "USER/CREATE_USER";
export const DELETE_USER = "USER/DELETE_USER";
export const SHOW_MODAL_LOGIN = "APP/SHOW_LOGIN_MODAL";
export const SHOW_MODAL_SIGNUP = "APP/SHOW_SIGNUP_MODAL";
export const SHOW_RIGHT_MODAL = "APP/SHOW_RIGHT_MODAL";
export const HIDE_RIGHT_MODAL = "APP/HIDE_RIGHT_MODAL";
export const FETCH_BASKET_PRODUCTS = "BASKET/FETCH_BASKET_PRODUCTS";
export const DELETE_BASKET_PRODUCT = "BASKET/DELETE_BASKET_PRODUCT";
export const CREATE_BASKET_PRODUCT = "BASKET/CREATE_BASKET_PRODUCT";
export const FETCH_USER_WISHLISTS = "WISHLIST/FETCH_USER_WISHLISTS";
export const DELETE_WISHLIST = "WISHLIST/DELETE_WISHLIST";
export const CREATE_WISHLIST = "WISHLISTS/CREATE_WISHLIST";
export const CREATE_CHECKOUT_IN_USER = "CHECKOUT/CREATE_CHECKOUT_IN_USER";
export const CREATE_BASKET_TO_LOCAL = "BASKET/CREATE_BASKET_TO_LOCAL";
export const DELETE_BASKET_IN_LOCAL = "BASKET/DELETE_BASKET_TO_LOCAL";
export const INCREMENT_BASKET_COUNTER = "BASKET/INCREMENT_BASKET_COUNTER";
export const DECREMENT_BASKET_COUNTER = "BASKET/DECREMENT_BASKET_COUNTER";
export const CREATE_MAP_USER = "USER/CREATE_MAP_USER";
export const DELETE_MAP_USER = "USER/DELETE_MAP_USER";
export const UPDATE_DEFAULT_MAP_USER = "USER/UPDATE_DEFAULT_MAP_USER";
export const UPDATE_MAP_USER = "USER/UPDATE_MAP_USER";
export const CREATE_EXCHANGE_RATES = "APP/CREATE_EXCHANGE_RATES";