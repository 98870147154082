const colors = {
    primary: {
        default: '#FEEE00',
        dark: '#FFCE39',
        hover:'#E44542',
    },
    white: '#FFFFFF',
    red: {
        default: '#E44542',
        light: '#FF888C',
        badge: '#EB133D',
    },
    green: {
        default: '#B5EE6F',
    },
    black: '#333333',
    grey: {
        default: '#999999',
        light: '#F5F5F6',
        lighter: '#EAEAEA',
        input: '#534343',
        brand: '#F8F8F8',
        controller: '#EDEDED',
        darc:'#808080',
        dlight:'#d9d9d9'
    },
};

export default colors;