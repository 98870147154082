import { Container } from "@mui/system";
import React from "react";
import { Link } from "react-router-dom";
import Title from "../../components/title/Title";
import SecondNavbar from "../../layout/navbar/SecondNavbar";

const UserAgreement = () => {
  return (
    <>
      <SecondNavbar />
      <section className="other">
        <Container maxWidth="xl">
          <Title title="ПРАВА И ОБЯЗАННОСТИ СТОРОН" />
          <p>6.1 Продавец обязуется:</p>
          <p>
            6.1.1. С момента заключения настоящего Договора обеспечить в полной
            мере все обязательства перед Покупателем в соответствии с условиями
            настоящего договора и действующего законодательства.
          </p>
          <p>
            6.1.2. Обрабатывать персональные данные Покупателя и обеспечивать их
            конфиденциальность в порядке, установленном действующим
            законодательством.
          </p>
          <p>
            6.1.3. Предоставить Покупателю возможность получения бесплатных
            телефонных консультаций по телефонам, указанным на сайте
            Интернет-магазина. Объем консультаций ограничивается конкретными
            вопросами, связанными с выполнениями Заказа и характеристиками
            Товара.
          </p>
          <p>
            6.1.4. Предотвращать попытки несанкционированного доступа к
            информации и/или передачу ее лицам, не имеющим непосредственного
            отношения к исполнению Заказов, своевременно обнаруживать и
            пресекать такие факты.
          </p>
          <p>6.2. Продавец имеет право:</p>
          <p>
            6.2.1. Изменять настоящий Договор, Цены на Товар и Тарифы на
            сопутствующие услуги, способы и сроки оплаты и доставки товара в
            одностороннем порядке, помещая их на страницах интернет-магазина,
            расположенного по интернет-адресу:{" "}
            <Link to="/">www.gipermart.uz</Link>
          </p>
          <p>
            6.2.2. Все изменения вступают в силу после публикации в сроках в
            соответствии с условиями Оферты и считаются доведенными до сведения
            Покупателя с момента такой публикации.
          </p>
          <p>
            6.2.3. Осуществлять записи телефонных переговоров с Покупателем.
          </p>
          <p>
            6.2.4. Без согласования с Покупателем, передавать свои права и
            обязанности по исполнению Договора третьим лицам.
          </p>
          <p>
            6.2.5. Передавать на хранение информационным посредникам электронные
            документы и электронные сообщения.
          </p>
          <p>
            6.2.6. Невыполнения обязательств по Договору в случае возникновения
            обстоятельств непреодолимой силы, указанных в главе ХI настоящего
            Договора, а также изменения условий выполнения обязательств в
            соответствии с п.4.4 настоящего договора.
          </p>
          <p>
            6.2.7. В случае если без каких либо уважительных причин Покупатель
            не оплатит и не примет заказанный Товар или не примет заказанный и
            ранее оплаченный им Товар поставленный в указанных сроках на ранее
            оговоренный адрес (территорию) Покупателем или третьим лицом
            (юридическим лицом оказывающий услуги по доставке Товара с которым
            Покупатель заключил договор на оказание транспортно-экспедиторских
            услуг) Покупатель вправе в одностороннем порядке расторгнуть договор
            и потребовать от Покупателя возмещения понесенных убытков или
            удержать Товар до того момента как Покупатель будет готов оплатить и
            принять или принять ранее оплаченный Товар в новые сроки,
            согласованные продавцом, после повторной оплаты покупателем
            стоимости услуг по доставке товара.
          </p>
          <p>
            6.2.8. Продавец вправе отказать Покупателю в приеме и исполнении
            заказа, если Покупатель делал ранее заказ и не оплачивал его,
            отказывался от заказа в момент доставки Товара, не принимал
            доставленный Товар, либо если у Продавца есть сомнения в
            достоверности указанных данных Покупателя, без объяснения причин.
          </p>
          <p>
            6.2.9. В случае, если у Продавца возникают основания предполагать,
            что предоставленная Пользователем при оформлении Заказа информация
            не соответствует действительности или предоставлена в неполном
            объеме, а также в случае оскорбительного и/или неадекватного
            поведения, Продавец имеет право отказать в приеме Заказа Покупателю
            на временной или постоянной основе.
          </p>
          <p>6.3. Покупатель обязуется:</p>
          <p>
            6.3.1. До момента заключения Договора ознакомиться с содержанием и
            условиями Договора, ценами на Товар, предлагаемыми Продавцом в
            интернет-магазине, а также другими условиями на страницах
            интернет-магазина, расположенного по интернет-адресу:
            <Link to="">www.gipermart.uz</Link>
          </p>
          <p>
            6.3.2. Во исполнение Продавцом своих обязательств перед Покупателем
            последний должен сообщить все необходимые данные, указанные в п.
            7.2. настоящей Оферты, однозначно идентифицирующие его как
            Покупателя, и достаточные для доставки Покупателю заказанного им
            Товара при условии доставки товара.
          </p>
          <p>
            6.3.3. Оплатить заказанный Товар и его доставку на условиях
            настоящего договора.
          </p>
          <p>
            6.3.4. Во избежание споров, при оформлении заказа ознакомиться с
            информацией, предложенной Продавцом на своем интернет-сайте.
          </p>
          <p>
            6.3.5. Не использовать товар, заказанный на интернет-сайте в
            предпринимательских целях.
          </p>
          <p>
            6.3.6. Оплатить и принять заказанный Товар от Покупателя или
            третьего лица (юридическим лицом оказывающий услуги по доставке
            Товара с которым Покупатель заключил договор на оказание
            транспортно-экспедиторских услуг) поставленный на ранее оговоренный
            адрес (территорию).
          </p>
          <Title title="ОБЩИЕ ПОЛОЖЕНИЯ" />
          <p>
            3.1. Заказ Покупателем Товара, размещенного на сайте
            интернет-магазина означает, что Покупатель согласен со всеми
            условиями настоящей Оферты, что является прямым акцептом условий
            договора Оферента.
          </p>
          <p>
            3.2. Администрация сайта Интернет-магазина имеет право вносить
            изменения в Оферту без уведомления Покупателя. В случае внесения
            изменений в действующую Оферту, изменения вступают в законную силу
            по истечению 10 календарных дней с даты официальной публикации на
            сайте.
          </p>
          <p>
            3.3. Срок действия Оферты не ограничен, если иное не указано на
            сайте Интернет-магазина.
          </p>
          <p>
            3.4. Продавец предоставляет Покупателю полную и достоверную
            информацию о Товаре, со всеми существенными условиями договора,
            включая информацию об основных потребительских свойствах Товара,
            месте изготовления, а также информацию о гарантийном сроке и сроке
            годности Товара на сайте Интернет-магазина ООО «FRIENDS CAPITAL» по
            интернет адресу: www.gipermart.uz
          </p>
        </Container>
      </section>
    </>
  );
};

export default UserAgreement;
