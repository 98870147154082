import React from 'react';

const EyeEnabled = (props) => (
  <svg
    width="20"
    height="13"
    viewBox="0 0 20 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.0002 0.333328C5.8335 0.333328 2.27516 2.92499 0.833496 6.58333C2.27516 10.2417 5.8335 12.8333 10.0002 12.8333C14.1668 12.8333 17.7252 10.2417 19.1668 6.58333C17.7252 2.92499 14.1668 0.333328 10.0002 0.333328ZM10.0002 10.75C7.70016 10.75 5.8335 8.88333 5.8335 6.58333C5.8335 4.28333 7.70016 2.41666 10.0002 2.41666C12.3002 2.41666 14.1668 4.28333 14.1668 6.58333C14.1668 8.88333 12.3002 10.75 10.0002 10.75ZM10.0002 4.08333C8.61683 4.08333 7.50016 5.2 7.50016 6.58333C7.50016 7.96666 8.61683 9.08333 10.0002 9.08333C11.3835 9.08333 12.5002 7.96666 12.5002 6.58333C12.5002 5.2 11.3835 4.08333 10.0002 4.08333Z"
      fill="currentColor"
    />
  </svg>
);

export default EyeEnabled;
